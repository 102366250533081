// ** Reducers Imports
import layout from '../layout'
import navbar from '../navbar'
import packageReducer from './PackageReducer'
import { customerReducer } from './CustomerReducer'
import mediaReducer from './MediaReducer'
import supportReducer from './SupportReducer'
import eventReducer from './EventReducer'
const rootReducer = {
  navbar,
  layout,
  packageReducer,
  customerReducer,
  mediaReducer,
  supportReducer,
  eventReducer
}

export default rootReducer
