import {
  EVENT_HISTORY_LIST,
  EVENT_HISTORY_TOTAL,
  EVENT_LIST,
  EVENT_TOTAL
} from '../../constants/ReducerConstants'

// import from files
const initialState = {
  event: [],
  totalEvent: 0,
  historyEvent: [],
  totalHistoryEvent: 0
}
// reducer for shop listing
const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_LIST:
      return { ...state, event: action.payload }
    case EVENT_TOTAL:
      return { ...state, totalEvent: action.payload }
    case EVENT_HISTORY_LIST:
      return { ...state, historyEvent: action.payload }
    case EVENT_HISTORY_TOTAL:
      return { ...state, totalHistoryEvent: action.payload }
    default: {
      return state
    }
  }
}
export default eventReducer
