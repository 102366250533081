import {
  RESOURCE_LIST,
  RESOURCE_TOTAL,
  VIDEO_LIST,
  VIDEO_TOTAL
} from '../../constants/ReducerConstants'

const initialState = {
  video: null,
  totalVideo: null,
  resources: null,
  totalResources: null
}

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_LIST:
      return { ...state, video: action.payload }
    case VIDEO_TOTAL:
      return { ...state, totalVideo: action.payload }
    case RESOURCE_LIST:
      return { ...state, resources: action.payload }
    case RESOURCE_TOTAL:
      return { ...state, totalResources: action.payload }
    default: {
      return state
    }
  }
}

export default mediaReducer
