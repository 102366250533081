const initState = {
  customerUserData: null,
  totalCustomerUserData: null
}
export const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CUSTOMER':
      return { ...state, partnerUserData: action.payload }
    case 'TOTAL_CUSTOMERS':
      return { ...state, totalpartnerUserData: action.payload }
    default:
      return state
  }
}
