//reducer constants
export const PACKAGE_LIST = 'PACKAGE_LIST'
export const PACKAGE_TOTAL = 'PACKAGE_TOTAL'

//localstorage constants

export const USER_ID = 'HipStrAdminUser'
export const BEARER_TOKEN = 'HipStrCustomerToken'
export const FIRST_NAME = 'HipStrCustomerFirstName'
export const LAST_NAME = 'HipStrCustomerLastName'
export const PROFILE_IMAGE = 'HipStrCustomerProfileImage'
export const USER_TYPE = 'HipStrCustomerType'
export const CUSTOMER_FCM_TOKEN = 'HipStrCustomerFcm'
export const MODULE_LIST = 'HipStrCustomerList'
export const REMEMBER = 'HipStrCustomerRemember'
export const USER = 'HipStrCustomerUser'

export const ROLE = 'Role'
export const MEMBER = 'Member'
//toast messages
export const LOGIN = 'Login successfully'
export const MAIL_SENT = 'Mail sent successfully'
export const PASSWORD_CHANGE = 'password change successfully'
export const LOGOUT = 'Logout successfully'
export const PASSWORD_RESET = 'Password reset successfully'
export const SELECT_ONE_PERMISSON = 'Please select at least one permission'
export const DELETE_MEMBER = 'Member deleted successfully'
export const MEMBER_ADD = 'Member added successfully'
export const MEMBER_EDIT = 'Member Updated successfully'
export const SET_PASSWORD = 'Password Set successfully'
export const PARTNER_ADD = 'Partner added successfully'
export const PARTNER_EDIT = 'Partner updated successfully'
export const CUSTOMER = 'customer'
export const NOTIFICATION_LIMIT = 3
export const MESSAGE_NOTIFICATION = 'message'
export const EVENT_NOTIFICATION = 'event'
export const TICKET_STATUS = new Map()
TICKET_STATUS.set('close', 'Closed')
TICKET_STATUS.set('inprogress', 'In Progress')
TICKET_STATUS.set('open', 'Open')
export const EVENT_STATUS = new Map()
EVENT_STATUS.set('Awaiting Event Details', 'Complete Your Event Details Form')
EVENT_STATUS.set('Event Details Received', 'Sit back and Relax. There’s no action required at this time. We’re prepping for your event on our end and will get back to you soon.')
EVENT_STATUS.set('Event Planning In Progress', 'Sit back and Relax. There’s no action required at this time. We’re prepping for your event on our end and will get back to you soon.')
EVENT_STATUS.set('Awaiting Host Details', 'There’s no action required at this time. We’re taking our final steps to complete our preparation for your event.')
EVENT_STATUS.set('Ready To Execute Event', 'There’s no additional action required at this time. We’re prepared for your event and looking forward to seeing you soon.')
EVENT_STATUS.set('Event Serviced', 'Your Event has been serviced. We hope you enjoyed your event.')
