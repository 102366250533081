import { SUPPORT_LIST, SUPPORT_TOTAL } from '../../constants/ReducerConstants'

// import from files
const initialState = {
  ticket: [],
  totalTicket: 0
}

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORT_LIST:
      return { ...state, ticket: action.payload }
    case SUPPORT_TOTAL:
      return { ...state, totalTicket: action.payload }
    default: {
      return state
    }
  }
}
export default supportReducer
