export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_TOTAL = 'ROLE_TOTAL'
export const MODULE_LIST = 'MODULE_LIST'
export const MODULE_TOTAL = 'MODULE_TOTAL'
export const PARTNER_LIST = 'PARTNER_LIST'
export const PARTNER_TOTAL = 'PARTNER_TOTAL'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const COUNTRY_TOTAL = 'COUNTRY_TOTAL'
export const SIDEBAR_LIST = 'SIDEBAR_LIST'
export const SIDEBAR_TOTAL = 'SIDEBAR_TOTAL'
export const MODULE_ACCESS_LIST = 'MODULE_ACCESS_LIST'
export const MODULE_ACCESS_TOTAL = 'MODULE_ACCESS_TOTAL'
export const VIDEO_LIST = 'VIDEO_LIST'
export const VIDEO_TOTAL = 'VIDEO_TOTAL'
export const RESOURCE_LIST = 'RESOURCE_LIST'
export const RESOURCE_TOTAL = 'RESOURCE_TOTAL'
export const SUPPORT_LIST = 'SUPPORT_LIST'
export const SUPPORT_TOTAL = 'SUPPORT_TOTAL'
export const EVENT_LIST = 'EVENT_LIST'
export const EVENT_TOTAL = 'EVENT_TOTAL'
export const EVENT_HISTORY_LIST = 'EVENT_HISTORY_LIST'
export const EVENT_HISTORY_TOTAL = 'EVENT_HISTORY_TOTAL'
export const MESSAGE_LIST = 'MESSAGE_LIST'
export const MESSAGE_TOTAL = 'MESSAGE_TOTAL'
